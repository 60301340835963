<template>
  <section class="space-1 bg-light forgot-password-section">
    <div class="container">
      <div class="d-flex justify-content-center mb-5">
        <router-link to="/">
          <img class="logo d-flex"
               src="@/assets/img/brand/logo.svg" alt="Logo">
        </router-link>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-5 card card-body py-5 px-5">
          <div v-if="!emailSent" class="px-3 py-3">
            <div class="mb-4">
              <h3>Forgot Password?</h3>
              <p class="mt-3 text-muted">Enter your account email address and we will
                send you a password reset link.</p>
            </div>
            <form>
              <div class="form-group">
                <label for="email">Email address</label>
                <input type="email" v-model="username" class="form-control" id="email" required>
                <div class="g-recaptcha mt-3" :data-sitekey="captchaKey"></div>
              </div>
              <div v-if="errorMessage" class="small red mt-0 mb-2">
                <i class="fa fa-exclamation-triangle"></i>&nbsp;
                {{errorMessage}}</div>
              <button type="button"
                      :disabled="!isEmailValid || sendingResetEmail"
                      @click="requestNewCode()"
                      class="btn btn-primary btn-block">
                <span v-if="sendingResetEmail">
                  <i class="fas fa-spin fa-spinner-third"></i>&nbsp;
                  Dispatching your email...
                </span>
                <span v-if="!sendingResetEmail">
                  Reset Password
                </span>
              </button>
            </form>
            <div class="mt-5 text-center">
              <p class="m-0">Remember password?</p>
              <router-link to="/login">Login</router-link>
            </div>
          </div>
          <div v-if="emailSent" class="px-3 py-3">
            <h3>Reset Password Email Sent!</h3>
            <p class="mt-3 text-muted">
              We sent you the password reset email. Check your inbox (and also your spam folders)
              for the email.
            </p>
            <div class="mb-4">
              <img class="onboarding-image"
                   style="width: 100%"
                   src="@/assets/img/signup/yougotmail.webp" alt="You got mail gif!" />
              <div class="image-attribution text-left">
                Tom Hanks / You've Got Mail / Warner Bros.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import userInfo from '@/app/user-info';
import utils from '@/app/utils';

const CAPTCHA_KEY = process.env.VUE_APP_CAPTCHA_2_CLIENT_KEY;

export default {
  computed: {
    isEmailValid() {
      if (this.username == null || this.username.trim() === '') {
        return false;
      }

      return utils.validateEmail(this.username);
    },
  },
  data() {
    return {
      captchaKey: CAPTCHA_KEY,
      username: null,
      errorMessage: null,
      emailSent: false,
      sendingResetEmail: false,
    };
  },
  methods: {
    async requestNewCode() {
      this.errorMessage = '';
      if (this.username == null || this.username.trim() === '' || !this.isEmailValid) {
        this.errorMessage = 'Please enter a valid email address.';
        return;
      }

      // eslint-disable-next-line no-undef
      const recaptcha = grecaptcha.getResponse();
      if (recaptcha == null || recaptcha === '') {
        this.errorMessage = 'Please complete the Captcha to continue.';
        return;
      }

      try {
        this.sendingResetEmail = true;
        await userInfo.requestPasswordReset(this.username, recaptcha);
        this.emailSent = true;
        this.sendingResetEmail = false;
      } catch (error) {
        this.sendingResetEmail = false;
        this.emailSent = false;

        let errorCode = null;
        if (error.response && error.response.data && error.response.data.code) {
          errorCode = error.response.data.code;
        }

        if (errorCode === 'ACCOUNT_NOT_FOUND') {
          this.errorMessage = 'I could not find the email address in our system.';
        } else {
          utils.showToastError('Could not send reset email',
            'An error occurred and I could not send you your password reset email. Please '
              + 'refresh the page and try again.', true);
        }
      }
    },
  },
  mounted() {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
    document.head.appendChild(recaptchaScript);
  },
};

</script>
